/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

@import "assets/custom/css/variables.scss";

// Theme customization
@import "theme/theme";
@import "theme/theme-functions";

// 3rd party libraries
@import "~material-design-icons-iconfont/src/material-design-icons";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($theme);

$pdfViewports: (0px: (pdf-height: 100%,
    total-height: 100%),
  1280px: (pdf-height: calc(100vh - (calc(53px + 52px) + 48px)),
    total-height: calc(100vh - (calc(53px + 52px) + 48px))));

@import "node_modules/@symblcrowd/ng-symblcrowd/lib/sc-all";
@include ng-symblcrowd-core();
@include sc-document-viewer-config($pdfViewports);

// import custom componenet themes
@import "./assets/custom/css/custom";

.theme-default {
  // Call the main material theme mixin to create all the default theming styles.
  @include ng-symblcrowd-theme($theme-default);
  // Call the custom component mixin to create all the custom component theming styles
  @include custom-components-theme($theme-default);
}

//
// Theme 1
//

// auto light/dark mode — depending on system preferences
.theme-1:not(.light):not(.dark) {
  @include ng-symblcrowd-theme($theme-client-1);
  @include custom-components-theme($theme-client-1);

  @media (prefers-color-scheme: light) {
    @include ng-symblcrowd-theme($theme-client-1);
    @include custom-components-theme($theme-client-1);
  }

  @media (prefers-color-scheme: dark) {
    @include ng-symblcrowd-theme($theme-client-1-dark);
    @include custom-components-theme($theme-client-1-dark);
  }

  @media (prefers-color-scheme: no-preference) {
    @include ng-symblcrowd-theme($theme-client-1);
    @include custom-components-theme($theme-client-1);
  }
}

// force light mode
.theme-1.light {
  @include ng-symblcrowd-theme($theme-client-1);
  @include custom-components-theme($theme-client-1);
}

// force dark mode
.theme-1.dark {
  @include ng-symblcrowd-theme($theme-client-1-dark);
  @include custom-components-theme($theme-client-1-dark);
}

.divider {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid lightgray; 
  line-height: 0.1em;
  margin: 20px 0 20px; 
} 

.divider .inner-text { 
  background: white;
  color: darkgray;
   padding:0 10px; 
}
