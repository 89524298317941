@use "sass:math";

//*
//* LAYOUT
//*

//* CONTENT (SHELL)
$content-padding: 24px;

//* HEADER (SHELL)
$mini-fab-size: 40px;
$topbar-height: 53px;
$actionbar-height: 52px;
$header-height: calc(#{$topbar-height} + #{$actionbar-height});
$header-height-mobile: calc(#{$mini-fab-size} + #{$content-padding});

//* SIDEBAR (SHELL)
$sidebar-condensed-width: 73px;
$sidebar-wide-width: 251px;

// padding left = 24px // padding right = 8px // margin between logo and button = 8px // button width = 40px
$brand-width: calc(#{$sidebar-wide-width} - (24px + 8px + 8px + 40px));

// padding-left = 24px // leading icon = 24px // trailing icon = 18px // padding-right = 8px
$nav-link-title-default-width: calc(#{$sidebar-wide-width} - (24px + 24px + 18px + 8px));

$sublist-offset: 8px;
$nav-link-title-lvl-1-width: calc(#{$nav-link-title-default-width} - #{$sublist-offset});
$nav-link-title-lvl-2-width: calc(#{$nav-link-title-default-width} - (#{$sublist-offset} * 2));
$nav-link-title-lvl-3-width: calc(#{$nav-link-title-default-width} - (#{$sublist-offset} * 3));

//* ACTIONBAR (SHELL)
$actionbar-button-spacing: 8px;

//* GRID
$grid-gap: 16px;

//* OVERVIEW PAGE
$sidenav-aside-width: 260px;

//*
//* COMPONENTS
//*

//* CARD
$card-border-radius: 8px;
$card-padding: $grid-gap;

//* DIALOG
$dialog-border-radius: $card-border-radius !default;
$dialog-padding: 24px !default;
$dialog-header-height: 57px !default;
$dialog-footer-height: 53px !default;
// $dialog-footer-height-wrapped: 89px !default; //? shipping dialog
// $dialog-min-outer-padding: 32px !default; //? shipping dialog

//* TOAST
//? Outer Container
$sc-toast-padding-y: 14px !default;
$sc-toast-padding-x: 16px !default;
$sc-toast-min-height: 48px !default;
$sc-toast-min-width: 344px !default;
$sc-toast-max-width: 33vw !default;
$sc-toast-spacing-margin: 24px !default;
$sc-toast-spacing-margin-handset: 8px !default;
//? Inner Content
$sc-toast-line-height: 20px !default;
$sc-toast-icon-button-height: 40px !default;
$sc-toast-button-height: 36px !default;
$sc-toast-button-margin-x: 8px !default;
//? Button vertical margin (y) is used to ensure that a button height of 36px, when the containing
//? space falls below 36px.
$sc-toast-button-margin-y: ((math.div(($sc-toast-button-height - $sc-toast-line-height), 2)) * -1);
// //? Button vertical margin is used to ensure that a ICON-button height of 36px, when the containing
// //? space falls below 36px.
$sc-toast-icon-button-margin-y: ((math.div(($sc-toast-button-height - $sc-toast-line-height), 2)) * -1);

//* SECTION INDICATOR
$section-indicator-width: 64px;

//* ALERTS
$alert-border-radius: $card-border-radius;
$alert-padding-y: 12px;
$alert-padding-x: 16px;

//*
//* TRANSITIONS / ANIMATION
//*

$shell-transition-duration: 0.28s;
$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1);
$hover-transition-duration: 0.15s;

//*
//* SPECIFIC TO EASYRENT
//*

//* Dashboard finance sections
$db-finance-sections-padding: 24px;
