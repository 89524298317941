@use '@angular/material' as mat;
// @import "src/assets/custom/css/variables";
@import "node_modules/@symblcrowd/ng-symblcrowd/lib/scss/sc-mixins";
@import "node_modules/@symblcrowd/ng-symblcrowd/lib/scss/sc-variables";

//*
//* FOCUS MODE
//*

$mat-tabs-height: 49px;

.layout__wrapper--focus-mode {
  .vh-100--shell {
    height: calc(100vh - (#{$content-padding} * 2 + #{$actionbar-height})) !important;

    @include media-breakpoint-up(sm) {
      height: calc(100vh - (#{$topbar-height} + #{$content-padding} * 2)) !important;
    }
  }

  .vh-100--shell-tabs {
    height: calc(100vh - (#{$content-padding} * 2 + #{$actionbar-height})) !important;

    .sc-table-container {
      height: calc(100% - #{$mat-tabs-height}) !important;
    }

    @media screen and (min-width: 600px) {
      height: calc(100vh - (#{$topbar-height} + #{$content-padding} * 2)) !important;
    }
  }
}

@mixin custom-styles-theme($theme) {
  $primary: map-get($theme, primary);
  $success: map-get($theme, success);
  $warning: map-get($theme, warning);
  $danger: map-get($theme, danger);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $scForeground: map-get($theme, sc-foreground);
  $scBackground: map-get($theme, sc-background);

  //
  // STUFF COPIED FROM THEME.SCSS
  //
  // todo: sort

  /*.mat-table-sticky {
  border-top: 1px solid #e0e0e0;
  }*/

  td.mat-table-sticky:first-child {
    border-right: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  td.mat-table-sticky:last-child {
    border-left: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  th.mat-table-sticky-header:first-child {
    border-right: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  th.mat-table-sticky-header:last-child {
    border-left: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .tab-content-wrapper {
    transform: none;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    justify-content: space-between;
  }

  .tab-content-footer {
    border-top: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .tab-content-footer-action {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0 !important;
  }

  //*
  //* EASYRENT STUFF
  //*

  .license-plate {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto Mono, monospace;
    font-weight: 500;
    font-size: 18px;
    color: mat.get-color-from-palette($foreground, text);
    border: 2px solid mat.get-color-from-palette($foreground, text);
    border-radius: 4px;
    background: mat.get-color-from-palette($background, card);
    // height: 36px;
    // width: 100%;
    margin-top: 2px;
  }

  //* DETAILS PAGE

  .ov-nav__nav-item-icon.mat-icon {
    width: 20px;
    height: 20px;
    opacity: 0.38;
    margin-right: 16px;
  }

  //*
  //* LISTS
  //*

  .icon-list {
    margin: 0;
    padding: 0;

    &__icon {
      padding: $grid-gap;
    }

    &__content {
      padding: 19px $grid-gap 19px 0;
      border-bottom: 1px solid;

      &-item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__item {
      display: grid;
      grid-template-columns: 72px minmax(0, 1fr);

      &:last-child {
        .icon-list__content {
          border-bottom: 0;
        }
      }
    }

    &.icon-list--2-lines {
      .icon-list__icon {
        padding-top: 21px;
      }

      .icon-list__content {
        padding: 14px $grid-gap 14px 0;
      }
    }
  }

  //* Contact Potential Interests (Sublist of Icon-List)

  .vehicle-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //* Description List

  //? HTML Description Lists
  //?
  //? A description list is a list of items with a description or definition of each item.
  //? The description list is created using <dl> element. The <dl> element is used in conjunction
  //? with the <dt> element which specify a term, and the <dd> element which specify the term's definition.

  dl,
  .descr-list {
    margin: 0;

    @media screen and (min-width: 600px) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: $grid-gap;
      grid-row-gap: calc(#{$grid-gap / 2});
    }

    dt,
    .descr-list__label {
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 0.033333333rem;
      color: mat.get-color-from-palette($foreground, secondary-text);
      padding-top: 7px;
      padding-bottom: 5px;
      margin: 0;
    }

    dd,
    .descr-list__content {
      min-height: 24px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 0 calc(#{$grid-gap / 2}) 0;

      &:last-child {
        margin: 0;
      }

      @media screen and (min-width: 600px) {
        margin: 0;
      }
    }
  }

  dl.descr-list--label-align-right,
  .descr-list.descr-list--label-align-right {
    dt,
    .descr-list__label {
      justify-self: right;
    }
  }

  //* Unstyled List

  .list {
    &--unstyled {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  //* Contact info list (with actions and optional toggle button)

  .contact-edit-list {
    &__item {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      align-items: start;
      border-bottom: 1px solid;
      grid-template-areas:
        "type  actions"
        "entry actions";

      &:last-child {
        border-bottom: 0;
      }

      @media screen and (min-width: 600px) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) auto;
        grid-template-areas: "type entry actions";
        align-items: baseline;
      }
    }

    &__entry {
      grid-area: entry;
      padding: 0 8px 0 0;

      @media screen and (min-width: 600px) {
        grid-column: auto;
        grid-row: auto;
        order: 1;
      }
    }

    &__type {
      grid-area: type;
      align-self: end;
      padding: 0 8px 0 0;

      @media screen and (min-width: 600px) {
        grid-column: auto;
        grid-row: auto;
        order: 2;
        align-self: baseline;
        padding: 0 8px;
      }
    }

    &__actions {
      grid-area: actions;
      display: flex;
      padding: 8px 0 8px 8px;

      @media screen and (min-width: 600px) {
        grid-column: auto;
        grid-row: auto;
        order: 3;
      }

      .mat-icon-button {
        margin-right: calc(#{$card-padding} / 4);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--toggle-fav {
      .contact-edit-list__item {
        grid-template-columns: auto minmax(0, 1fr) auto;
        grid-template-areas:
          "favorite type  actions"
          "favorite entry actions";

        @media screen and (min-width: 600px) {
          grid-template-columns: auto minmax(0, 1fr) minmax(0, 1fr) auto;
          grid-template-areas: "favorite type entry actions";
        }
      }

      .contact-edit-list__fav {
        grid-area: favorite;
        padding: 8px 8px 8px 0;
      }

      .contact-edit-list__type {
        @media screen and (min-width: 600px) {
          padding: 0 8px;
        }
      }

      .contact-edit-list__entry {
        @media screen and (min-width: 600px) {
          padding: 0 8px;
        }
      }
    }
  }

  //*
  //* GRADIENTS
  //*

  .expansion-text-panel--collapsed .expansion-text-panel__trigger.gradient {
    background: linear-gradient(
      0deg,
      mat.get-color-from-palette($background, card) 0%,
      mat.get-color-from-palette($background, card) 44%,
      rgba(mat.get-color-from-palette($background, card), 0) 100%
    );
  }

  //*
  //* RADIO-/ CHECKBOX-GROUP
  //*

  .radio-group,
  .checkbox-group {
    display: flex;
    flex-direction: column;
    // margin: 15px 0;

    &__button {
      margin: 5px;
    }

    &__title {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  //*
  //* FORM
  //*

  .slide-toggle-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;

    .mat-slide-toggle {
      margin-left: 1rem;
    }
  }

  //* INPUT-GROUP

  .mat-input-group {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    & {
      > :first-child {
        flex: 1 1;

        .mat-form-field-appearance-outline .mat-form-field-outline-end {
          border-radius: 0 !important;
        }

        // removing border-right and preserving the borders for :focus and :hover state
        .mat-form-field-appearance-outline:not(.mat-focused)
          .mat-form-field-flex:not(:hover)
          .mat-form-field-outline-end {
          border-right: 0 !important;
        }
      }

      > :last-child {
        flex: 1 1;

        .mat-form-field-appearance-outline .mat-form-field-outline-start {
          border-radius: 0px !important;
        }
      }

      > :not(:first-child):not(:last-child) {
        flex: 1 1;

        .mat-form-field-appearance-outline .mat-form-field-outline-start {
          border-radius: 0px !important;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline-end {
          border-radius: 0px !important;
        }

        // removing border-right and preserving the borders for :focus and :hover state
        .mat-form-field-appearance-outline:not(.mat-focused)
          .mat-form-field-flex:not(:hover)
          .mat-form-field-outline-end {
          border-right: 0 !important;
        }
      }
    }
  }

  //*
  //* ICONS
  //*

  .mat-icon.icon-16 {
    width: 16px !important;
    height: 16px !important;
  }

  .icon-18 {
    width: 18px !important;
    height: 18px !important;
  }

  // todo: Sort

  .img-fluid {
    max-width: 100%;
  }

  .small {
    font-size: 0.7rem;
    line-height: 1;
  }

  //*
  //* BUTTONS
  //*

  .btn {
    &-inline {
      margin: 2px 0 !important;
      padding: 0.5em !important;
    }

    &__ghost-card {
      display: grid;
      place-content: center;
      border-radius: $card-border-radius;
      padding: $content-padding;
      background-color: rgba(mat.get-color-from-palette($background, card), 0.75);
      border: 1px dashed mat.get-color-from-palette($primary, 500);
      cursor: pointer;
      transition: border-color, background-color, color, $hover-transition-duration ease-out;

      &:hover {
        border-color: mat.get-color-from-palette($primary, 500);
        background-color: rgba(mat.get-color-from-palette($background, card), 1);
        transition: border-color, background-color, color, $hover-transition-duration ease-in;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  //*
  //* PROFILE IMAGES
  //*

  .circle-img {
    background: rgba(15, 28, 63, 0.125);
    border-radius: 50%;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;

    .circle-img__fill {
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @supports (object-fit: cover) {
      .circle-img__fill {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .profile-image {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin: 5px;
    line-height: 36px;
    border-radius: 50%;
    background-size: cover;
    font-family: IBM Plex Sans Condensed;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    // text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    color: mat.get-color-from-palette($foreground, secondary-text);
    background-color: mat.get-color-from-palette($background, hover);

    &--xxl {
      width: 128px;
      height: 128px;
      flex: 0 0 128px;
      line-height: 128px;
      font-size: 64px;
    }
    &--xl {
      width: 92px;
      height: 92px;
      flex: 0 0 92px;
      line-height: 92px;
      font-size: 32px;
    }
    &--lg {
      width: 64px;
      height: 64px;
      flex: 0 0 64px;
      line-height: 64px;
      font-size: 32px;
    }
    &--md {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      line-height: 40px;
      font-size: 24px;
    }
    &--sm {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      line-height: 24px;
      font-size: 12px;
    }
    &--xs {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      line-height: 16px;
      font-size: 8px;
      font-weight: normal;
    }
  }


  // end theming mixin
}
