/*
 * Application global variables.
 */
@use '@angular/material' as mat;

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@import "@angular/material/theming";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

//you can generate theme palette here: http://mcg.mbitson.com/
// changed contrast colors for 500, 600 and 700
$sc-color: (
  50: #fee5e4,
  // Anpassung von #fff3e0 für Rot
  100: #fec4c2,
  // Anpassung von #ffe0b2 für Rot
  200: #fd9e9b,
  // Anpassung von #ffcc80 für Rot
  300: #fc7974,
  // Anpassung von #ffb74d für Rot
  400: #fa545d,
  // Anpassung von #ffa726 für Rot
  500: #ee8682,
  // Dein Hauptrot #EE8682
  600: #fa2629,
  // Anpassung von #fb8c00 für Rot
  700: #ee0003,
  // Anpassung von #f57c00 für Rot
  800: #d40000,
  // Anpassung von #ef6c00 für Rot
  900: #ae0000,
  // Anpassung von #e65100 für Rot
  A100: #ffffff,
  // Anpassung von #ffd180 für Rot
  A200: #ffc2c0,
  // Anpassung von #ffab40 für Rot
  A400: #ff9694,
  // Anpassung von #ff9100 für Rot
  A700: #ff7573,
  // Anpassung von #ff6d00 für Rot
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: black)
);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

$primary: mat.define-palette(mat.$blue-palette);
$accent: mat.define-palette(mat.$grey-palette);
$grey: mat.define-palette(mat.$grey-palette);
$warn: mat.define-palette(mat.$red-palette);
// $success: mat-palette($mat-green);

$danger: mat.get-color-from-palette($warn, "default"); // #f4516c;
// $success: mat-color($success, "default"); //#34bfa3;

$config: mat.define-typography-config();
